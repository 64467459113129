.left-drawer-logo-section {
  animation-name: moveInleft;
  animation-duration: 5s;
  display: none;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px) rotate(0deg);
  }
  30% {
    transform: rotate(50deg);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
