nav::-webkit-scrollbar {
  width: 0.5em;
}

nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

nav::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
  outline: 1px solid slategrey;
}
