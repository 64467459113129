html,
body {
  margin: 0;
  padding: 0;
  min-width: 360px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #575757 !important;
}

@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,600,700&amp;subset=latin-ext');
