.circleLeft {
  top: -3px;
  left: -2px;
  background: #52a3fc;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.circleRight {
  top: -3px;
  right: -2px;
  background: #20d4d5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.circleAlert {
  background: #e86958;
}

.meter {
  width: 100%;
  height: 3px;
  position: relative;
  margin: 0px;
  background: #ebebef;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0px;
}

.meter > div.middleLine {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: linear-gradient(to right, #52a3fc, #20d4d5);
  position: relative;
  overflow: hidden;
}

.meter > div.middleLine.alert {
  background-image: linear-gradient(to right, #e86958, #f66653);
}

.meter > div.middleLine:after,
.animate > span > span {
  content: '';
  z-index: 1;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-color: #f1a165;
  background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f1a165),
    color-stop(1, #f36d0a)
  );
  background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-color: #f0a3a3;
  background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f0a3a3),
    color-stop(1, #f42323)
  );
  background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span:after {
  -webkit-animation: none;
  background-image: none;
}
