.dropzoneTextStyle {
  text-align: center;
  top: 25%;
  position: relative;
  padding: 30px;
}

.uploadIconSize {
  width: 51px !important;
  height: 51px !important;
  color: #909090 !important;
}

.dropZone {
  position: relative;
  width: 100%;
  height: 250px;
  cursor: pointer;
}

.stripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #c8c8c8;
  background-image: repeating-linear-gradient(
    -45deg,
    #f0f0f0,
    #f0f0f0 25px,
    #c8c8c8 25px,
    #c8c8c8 50px
  );
  -webkit-animation: progress 2s linear infinite !important;
  -moz-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.rejectStripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #c8c8c8;
  background-image: repeating-linear-gradient(
    -45deg,
    #fc8785,
    #fc8785 25px,
    #f4231f 25px,
    #f4231f 50px
  );
  -webkit-animation: progress 2s linear infinite !important;
  -moz-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.fileIconImg {
  color: #909090 !important;
}

.smallPreviewImg {
  height: 90px !important;
  width: initial !important;
  max-width: 120px;
  margin-top: 5px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px !important;
  border-radius: 2px;
  z-index: 5;
}

.imageContainer:last-of-type .smallPreviewImg {
  margin-right: 0px;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@-moz-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@-ms-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.imageContainer {
  position: relative;
  z-index: 10;
}

.imageContainer:hover .smallPreviewImg {
  opacity: 0.3;
}

.imageContainer:hover .middle {
  opacity: 1;
}

.imageContainer:hover .middleBigPic {
  opacity: 1;
}

.removeBtn {
  transition: 1s ease;
  color: black;
  z-index: 3;
  font-size: 1.5em;
  opacity: 0.3;
}

.removeBtn:hover {
  transition: 0.5s ease;
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 25px;
  left: 20px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 0 1 auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
