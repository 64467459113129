/* width */
ul::-webkit-scrollbar {
  width: 4px;
}

/* Track */
ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b0b0;
  border-radius: 5px;
}

/* Handle */
ul::-webkit-scrollbar-thumb {
  background: #52a3fc;
  border-radius: 5px;
}

/* Handle on hover */
ul::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

div.boxContainer::-webkit-scrollbar {
  width: 4px;
}

/* Track */
div.boxContainer::-webkit-scrollbar-track {
  border-left: 1px solid #ebebef;
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 5px;
}

/* Handle */
div.boxContainer::-webkit-scrollbar-thumb {
  background: #52a3fc;
  border-radius: 5px;
}

/* Handle on hover */
div.boxContainer::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}
