@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 210, 141);
    border-radius: 30px;
  }
  100% {
    box-shadow: 0 0 0 10px rgb(252, 246, 246);
    border-radius: 30px;
  }
}

.pulsarBtn {
  border-radius: 50%;
  animation: shadow-pulse 1s infinite;
}
