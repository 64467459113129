.outer-circle {
  background: #52a3fc;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  cursor: pointer;
}

.inner-circle {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  top: 50%;
  left: 50%;
  margin: -15px 0px 0px -15px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: #52a3fc;
  cursor: pointer;
}
