@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(3rem);
  }
  50% {
    transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-3rem) rotate(0deg);
  }
  50% {
    transform: translateX(3rem) rotate(30deg);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveFromBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
