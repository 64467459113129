@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,600,700&amp;subset=latin-ext);
@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
            transform: translateX(3rem);
  }
  50% {
    -webkit-transform: translateX(-3rem);
            transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(3rem);
            transform: translateX(3rem);
  }
  50% {
    -webkit-transform: translateX(-3rem);
            transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3rem) rotate(0deg);
            transform: translateX(-3rem) rotate(0deg);
  }
  50% {
    -webkit-transform: translateX(3rem) rotate(30deg);
            transform: translateX(3rem) rotate(30deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-3rem) rotate(0deg);
            transform: translateX(-3rem) rotate(0deg);
  }
  50% {
    -webkit-transform: translateX(3rem) rotate(30deg);
            transform: translateX(3rem) rotate(30deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@-webkit-keyframes moveFromBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveFromBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.dropzoneTextStyle {
  text-align: center;
  top: 25%;
  position: relative;
  padding: 30px;
}

.uploadIconSize {
  width: 51px !important;
  height: 51px !important;
  color: #909090 !important;
}

.dropZone {
  position: relative;
  width: 100%;
  height: 250px;
  cursor: pointer;
}

.stripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #c8c8c8;
  background-image: repeating-linear-gradient(
    -45deg,
    #f0f0f0,
    #f0f0f0 25px,
    #c8c8c8 25px,
    #c8c8c8 50px
  );
  -webkit-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.rejectStripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #c8c8c8;
  background-image: repeating-linear-gradient(
    -45deg,
    #fc8785,
    #fc8785 25px,
    #f4231f 25px,
    #f4231f 50px
  );
  -webkit-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.fileIconImg {
  color: #909090 !important;
}

.smallPreviewImg {
  height: 90px !important;
  width: initial !important;
  max-width: 120px;
  margin-top: 5px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px !important;
  border-radius: 2px;
  z-index: 5;
}

.imageContainer:last-of-type .smallPreviewImg {
  margin-right: 0px;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.imageContainer {
  position: relative;
  z-index: 10;
}

.imageContainer:hover .smallPreviewImg {
  opacity: 0.3;
}

.imageContainer:hover .middle {
  opacity: 1;
}

.imageContainer:hover .middleBigPic {
  opacity: 1;
}

.removeBtn {
  transition: 1s ease;
  color: black;
  z-index: 3;
  font-size: 1.5em;
  opacity: 0.3;
}

.removeBtn:hover {
  transition: 0.5s ease;
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 25px;
  left: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.circleLeft {
  top: -3px;
  left: -2px;
  background: #52a3fc;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.circleRight {
  top: -3px;
  right: -2px;
  background: #20d4d5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.circleAlert {
  background: #e86958;
}

.meter {
  width: 100%;
  height: 3px;
  position: relative;
  margin: 0px;
  background: #ebebef;
  border-radius: 25px;
  padding: 0px;
}

.meter > div.middleLine {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: linear-gradient(to right, #52a3fc, #20d4d5);
  position: relative;
  overflow: hidden;
}

.meter > div.middleLine.alert {
  background-image: linear-gradient(to right, #e86958, #f66653);
}

.meter > div.middleLine:after,
.animate > span > span {
  content: '';
  z-index: 1;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-color: #f1a165;
  background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-color: #f0a3a3;
  background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span:after {
  -webkit-animation: none;
  background-image: none;
}

@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 210, 141);
    border-radius: 30px;
  }
  100% {
    box-shadow: 0 0 0 10px rgb(252, 246, 246);
    border-radius: 30px;
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 210, 141);
    border-radius: 30px;
  }
  100% {
    box-shadow: 0 0 0 10px rgb(252, 246, 246);
    border-radius: 30px;
  }
}

.pulsarBtn {
  border-radius: 50%;
  -webkit-animation: shadow-pulse 1s infinite;
          animation: shadow-pulse 1s infinite;
}

.outer-circle {
  background: #52a3fc;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  cursor: pointer;
}

.inner-circle {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  top: 50%;
  left: 50%;
  margin: -15px 0px 0px -15px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: #52a3fc;
  cursor: pointer;
}

/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}

.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel {
  position: relative;
  width: 100%;
  /*height: calc(100% - 100px);*/
}

.carousel * {
  box-sizing: border-box;
}

.carousel button {
  outline: 0;
  border: 0;
  background: none;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
  padding: 2px;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height 0.15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}

.carousel .slider.animated {
  transition: all 0.35s ease-in-out;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #ffffff;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}

.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}

.carousel .slide .legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 50%;
  height: 100%;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #fff;
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 10px 30px 10px;
  transition: opacity 0.35s ease-in-out;
}

@media (max-width: 768px) {
  .carousel .slide .legend {
    position: absolute;
    bottom: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #fff;
    display: -moz-flex;
    display: flex;
    justify-content: center;
    padding: 30px 10px 30px 10px;
  }
}

.carousel .slide .legend .legend-content {
  display: -moz-flex;
  display: flex;
  justify-content: center;
  width: calc(100% - 25%);
  margin: 0px auto;
}

.carousel .slide .legend .legend-content .legend-box {
  color: white;
  align-self: center;
  width: calc(100% - 10%);
  text-align: left;
  margin: 0px auto;
}

.carousel .slide .legend .legend-footer {
  text-align: center;
  color: #000;
  font-size: 26px;
}

.carousel .slide .legend .legend-footer a {
  color: #52a3fc;
  text-decoration: none;
}

.carousel .slide .legend .legend-footer p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .carousel .slide .legend {
    padding: 10px;
  }
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 50%;
  left: 50%;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 40px;
  }
}

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 1;
  filter: alpha(opacity=100);
  background: #52a3fc;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #000;
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}

.carousel:hover .slide .legend {
  opacity: 0.9;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  background: #e6e6e6;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #20d2d2;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.left-drawer-logo-section {
  -webkit-animation-name: moveInleft;
          animation-name: moveInleft;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  display: none;
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) rotate(0deg);
            transform: translateX(-100px) rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
  50% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px) rotate(0deg);
            transform: translateX(-100px) rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
  50% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

nav::-webkit-scrollbar {
  width: 0.5em;
}

nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

nav::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
  outline: 1px solid slategrey;
}

.recharts-wrapper {
  cursor: pointer !important;
}

/* width */
ul::-webkit-scrollbar {
  width: 4px;
}

/* Track */
ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b0b0;
  border-radius: 5px;
}

/* Handle */
ul::-webkit-scrollbar-thumb {
  background: #52a3fc;
  border-radius: 5px;
}

/* Handle on hover */
ul::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

div.boxContainer::-webkit-scrollbar {
  width: 4px;
}

/* Track */
div.boxContainer::-webkit-scrollbar-track {
  border-left: 1px solid #ebebef;
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 5px;
}

/* Handle */
div.boxContainer::-webkit-scrollbar-thumb {
  background: #52a3fc;
  border-radius: 5px;
}

/* Handle on hover */
div.boxContainer::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0;
}

html,
body {
  margin: 0;
  padding: 0;
  min-width: 360px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #575757 !important;
}

